/*
 * NOTE: use fontawesome 5 free icons or icomoon icons but use full icon class, with fas etc.
 * FA 5: https://fontawesome.com/icons?d=gallery&q=buildin&m=free
 * IcoMoon: https://iconsmind.com/view_icons/
 */
// Todo remove for development only

const listManagementCrumbs = [
	{ name: "Home", path: "/" },
	{ name: "Compliance", path: "/compliance" },
	{
		name: "List Management",
		path: "/compliance/list-management/rules",
	},
];

export enum AdminRoles {
	WorkFlowAdmin = "workflow.admin",
}

const listManagementDefaults = {
	path: "/compliance/list-management/rules",
	name: "List Management",
	icon: "fas fa-list",
	showWelcome: false,
	crumbs: listManagementCrumbs,
};

export const ROUTESINDIVIDUAL = {
	ALLTICKETS: {
		path: "/support/tickets",
		name: "Active Tickets",
		icon: "icon-Gaugage",
		showWelcome: false,
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Support Services", path: "/support" },
			{
				name: "Active Tickets",
				path: "/support/tickets",
			},
		],
	},
	VIEWS: {
		path: "/support/views",
		name: "Views",
		icon: "icon-Gaugage",
		showWelcome: false,
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Support Services", path: "/support" },
			{
				name: "Views",
				path: "/support/views",
			},
		],
	},
	EDITTICKET_VIEWS: {
		path: "/support/views",
		name: "Edit Ticket",
		icon: "icon-Gaugage",
		showWelcome: false,
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Support Services", path: "/support" },
			{
				name: "Views",
				path: "/support/views",
			},
		],
	},
	EDITTICKET_ALLTICKETS: {
		path: "/support/views",
		name: "Edit Ticket",
		icon: "icon-Gaugage",
		showWelcome: false,
		crumbs: [
			{ name: "Home", path: "/" },
			{ name: "Support Services", path: "/support" },
			{
				name: "Active Tickets",
				path: "/support/tickets",
			},
		],
	},
};

const Routes = [
	{
		name: "Communications",
		abbrev: "CO",
		icon: "fas fa-bullhorn",
		rootpath: "/communications",
		paths: [
			{
				path: "/communications",
				name: "Dashboard",
				icon: "icon-Gaugage",
				showWelcome: true,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
				],
			},
			{
				path: "/communications/campaigns",
				name: "Campaigns",
				icon: "fas fa-envelope",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Campaigns", path: "/communications/campaigns" },
				],
			},
			{
				path: "/communications/audiences",
				name: "Audiences",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Audiences", path: "/communications/audiences" },
				],
			},
			{
				path: "/communications/reports",
				name: "Reports",
				icon: "fas fa-chart-bar",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Reports", path: "/communications/reports" },
				],
			},
			{
				path: "/communications/templates",
				name: "Templates",
				icon: "fas fa-file",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Templates", path: "/communications/templates" },
				],
			},
			{
				path: "/communications/articles",
				name: "Articles",
				icon: "fas fa-file",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Articles", path: "/communications/articles" },
				],
			},
			{
				path: "/communications/media",
				name: "Media Manager",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Communications", path: "/communications" },
					{ name: "Media Manager", path: "/communications/media" },
				],
			},
		],
	},
	{
		name: "Operations",
		icon: "fas fa-building",
		abbrev: "OP",
		rootpath: "/operations",
		paths: [
			{
				path: "/operations/system-alerts",
				name: "System Messages",
				icon: "fas fa-bell",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/audience-applications",
				name: "Applications",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/custom-canvases",
				name: "Custom Canvases",
				icon: "fas fa-table",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/eula",
				name: "EULA Manager",
				icon: "fas fa-file-signature",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/email-validator",
				name: "Email Validator",
				icon: "fas fa-envelope",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
			{
				path: "/operations/media",
				name: "Media Manager",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
		],
		adminPaths: [
			{
				accessRole: AdminRoles.WorkFlowAdmin,
				path: "/operations/work-items/admin/work-item-definitions",
				name: "Tickets Admin",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Operations", path: "/operations" },
				],
			},
		],
	},
	{
		name: "Membership",
		icon: "fas fa-users-cog",
		abbrev: "ME",
		rootpath: "/membership",
		paths: [
			{
				path: "/membership/aors",
				name: "AORs",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "AORs", path: "/membership/aors" },
				],
			},
			{
				path: "/membership/settings",
				name: "Settings",
				icon: "fas fa-cogs",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Settings", path: "/membership/settings" },
				],
			},
		],
	},
	{
		name: "Training",
		icon: "fas fa-graduation-cap",
		abbrev: "TR",
		rootpath: "/training",
		paths: [
			{
				path: "/training",
				name: "Dashboard",
				icon: "icon-Gaugage",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
				],
			},
			{
				path: "/training/events",
				name: "Training Events",
				icon: "far fa-calendar-alt",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Training Events", path: "/training/events" },
				],
			},
			{
				path: "/training/topics",
				name: "Topics",
				icon: "fas fa-book",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Courses", path: "/training/topics" },
				],
			},
			{
				path: "/training/locations",
				name: "Locations",
				icon: "fas fa-building",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Attendees", path: "/training/locations" },
				],
			},
			{
				path: "/training/instructors",
				name: "Instructors",
				icon: "fas fa-chalkboard-teacher",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Instructors", path: "/training/instructors" },
				],
			},
			{
				path: "/training/registrations",
				name: "Registrations",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Registrations", path: "/training/registrations" },
				],
			},
			{
				path: "/training/requests",
				name: "Class Requests",
				icon: "fas fa-users",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Class Requests", path: "/training/requests" },
				],
			},
			{
				path: "/training/media",
				name: "Media Manager",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Training", path: "/training" },
					{ name: "Media Manager", path: "/training/media" },
				],
			},
		],
	},

	{
		name: "Support Services",
		icon: "fas fa-heart",
		abbrev: "SS",
		rootpath: "/support",
		paths: [
			{
				path: "/support/views",
				name: "Views",
				icon: "icon-Gaugage",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Support Services", path: "/support" },
					{
						name: "Views",
						path: "/support/views",
					},
				],
			},
			{
				path: "/support/tickets",
				name: "Active Tickets",
				icon: "icon-Gaugage",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Support Services", path: "/support" },
					{
						name: "Active Tickets",
						path: "/support/tickets",
					},
				],
			},
			{
				path: "/support/closed",
				name: "Closed Tickets",
				icon: "icon-Gaugage",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Support Services", path: "/support" },
					{
						name: "Closed Tickets",
						path: "/support/closed",
					},
				],
			},
			{
				path: "/support/reports",
				name: "Reports",
				icon: "icon-Gaugage",
				showWelcome: false,

				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Support Services", path: "/support" },
				],
			},
		],
	},
	{
		name: "Finance",
		icon: "fas fa-search-dollar",
		abbrev: "FI",
		rootpath: "/finance/media",
		paths: [
			{
				path: "/finance/media",
				name: "Media Manager",
				icon: "fas fa-photo-video",
				showWelcome: false,
				crumbs: [
					{ name: "Home", path: "/" },
					{ name: "Media Manager", path: "/finance/media" },
				],
			},
		],
	},
];
// Compliance Sections
Routes.push({
	name: "Compliance",
	icon: "fas fa-user-shield",
	abbrev: "CP",
	rootpath: "/compliance",
	paths: [
		{
			path: "/compliance/dashboard",
			name: "Dashboard",
			icon: "icon-Gaugage",
			showWelcome: false,

			crumbs: [
				{ name: "Home", path: "/" },
				{ name: "Compliance", path: "/compliance" },
				{
					name: "Dashboard",
					path: "/compliance/dashboard",
				},
			],
		},
		{
			path: "/compliance/queue",
			name: "Case Management",
			icon: "fas fa-archive",
			showWelcome: false,

			crumbs: [
				{ name: "Home", path: "/" },
				{ name: "Compliance", path: "/compliance" },
				{
					name: "Queue",
					path: "/compliance/queue",
				},
			],
		},
		{
			path: "/compliance/report-queue",
			name: "Report Management",
			icon: "fas fa-archive",
			showWelcome: false,

			crumbs: [
				{ name: "Home", path: "/" },
				{ name: "Compliance", path: "/compliance" },
				{
					name: "Report Management",
					path: "/compliance/report-queue",
				},
			],
		},
		{
			path: "/compliance/note-reminders",
			name: "Upcoming Reminders",
			icon: "fas fa-bell",
			showWelcome: false,

			crumbs: [
				{ name: "Home", path: "/" },
				{ name: "Compliance", path: "/compliance" },
				{
					name: "Upcoming Reminders",
					path: "/compliance/note-reminders",
				},
			],
		},
		{
			...listManagementDefaults,
			adminOnly: true,
			subPaths: ["/compliance/list-management/categories", "/compliance/list-management/reasons", "/compliance/list-management/dispositions", "/compliance/list-management/status"],
			crumbs: [
				...listManagementCrumbs,
				{
					name: "Rules",
					path: "/compliance/list-management/rules",
				},
			],
		},
		{
			...listManagementDefaults,
			path: "/compliance/list-management/categories",
			childPath: true,
			crumbs: [
				...listManagementCrumbs,
				{
					name: "Reason Categories",
					path: "/compliance/list-management/categories",
				},
			],
		},
		{
			...listManagementDefaults,
			path: "/compliance/list-management/reasons",
			childPath: true,
			crumbs: [
				...listManagementCrumbs,
				{
					name: "Report Reasons",
					path: "/compliance/list-management/reasons",
				},
			],
		} as any,
		{
			...listManagementDefaults,
			path: "/compliance/list-management/dispositions",
			childPath: true,
			crumbs: [
				...listManagementCrumbs,
				{
					name: "Resolutions",
					path: "/compliance/list-management/dispositions",
				},
			],
		},
		{
			path: "/compliance/case-detail",
			name: "Case Management",
			icon: "fas fa-archive",
			showWelcome: false,
			childPath: true,
			crumbs: [
				{ name: "Home", path: "/" },
				{ name: "Compliance", path: "/compliance" },
				{
					name: "Queue",
					path: "/compliance/queue",
				},
			],
		},
		{
			path: "/compliance/history",
			name: "Agent Profile",
			icon: "fas fa-user-shield",
			showWelcome: false,

			crumbs: [
				{ name: "Compliance", path: "/compliance" },
				{
					name: "History",
					path: "/compliance/History",
				},
			],
		},
		// {
		// 	path: "/compliance/buyer-registration",
		// 	name: "Buyer Registration",
		// 	icon: "fas fa-file-signature",
		// 	showWelcome: false,

		// 	crumbs: [
		// 		{ name: "Compliance", path: "/compliance" },
		// 		{
		// 			name: "Buyer Registration",
		// 			path: "/compliance/buyer-registration",
		// 		},
		// 	],
		// },
	],
});

export default Routes;
